html {
  height: 100%;
  background-color: #000;
}
body {
  min-height: 100%;
  background-color: #000;
}
#root {
    min-height: 100vh;
}
input[type="tel"]{
  border: 1px solid #FF40D7;
}
body{
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	font-size:90% !important;
	color: #000E4D !important;
}
.wrapper {
  display: flex;
  width: 100vw;
  height: 100vh;
  text-align: center;
}

.item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

}
.minh{
  height: 100%;
  display: block;
}

.card-2 {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #333;
    background-clip: border-box;
    border: 1px solid #fff;
    border-radius: .25rem;
}
.separador{
  position: absolute;
  right:0;
  top: 5%;
  width: 3px;
  background: #fff;
  height: 90%;
}
.bigText{
  font-size: 60px;
}

.bigText2{
  font-size: 80px;
}


.cuadroText{
  width: 200px;
  height: 200px;
  background: yellow;
  color: #333;
  /* Safari 3-4, iOS 1-3.2, Android 1.6- */
  -webkit-border-radius: 200px;

  /* Firefox 1-3.6 */
  -moz-border-radius: 200px;

  /* Opera 10.5, IE 9, Safari 5, Chrome, Firefox 4, iOS 4, Android 2.1+ */
  border-radius: 200px;
}
